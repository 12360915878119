'use client';

// Libraries
import { Container, ThemeProvider } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';

// Customize
import Metadata from '@/components/Metadata';
import GoogleAnalytics from '@/components/GoogleAnalytics';

// Variables
const Footer = dynamic(() => import('@/components/Footer'), { ssr: false });
const Header = dynamic(() => import('@/components/Header'));
const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });

library.add(fas, fab);

export default function LayoutHeaderNormal({
  children,
  canonical,
  title,
  description,
  image,
  imageType,
  imageWidth,
  imageHeight,
}) {
  return (
    <>
      <Metadata
        canonical={canonical}
        title={title}
        description={description}
        image={image}
        imageType={imageType}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      <ThemeProvider>
        <Container
          fluid
          className={inter.className + ' top-50'}
          data-bs-theme="dicloud">
          <Header />
          <GoogleAnalytics />
          <main className="fixed-header">{children}</main>
          <Footer />
        </Container>
      </ThemeProvider>
    </>
  );
}
